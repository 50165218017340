//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-312:_7266,_5160,_7136,_1848,_7430,_4632,_7048,_977;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-312')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-312', "_7266,_5160,_7136,_1848,_7430,_4632,_7048,_977");
        }
      }catch (ex) {
        console.error(ex);
      }